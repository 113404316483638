import { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { AuthPage } from '../pages/auth/AuthPage'
import { Logout } from '../pages/auth/login/Logout'
import { ErrorsPage } from '../modules/errors/ErrorsPage'

const Routes: FC = () => {
  const isAuthorized = localStorage.getItem("user");
  
  return (
    <Switch>
      {/*Render auth page when user at `/auth` and not authorized.*/}
      {!isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />
      {/*Redirect to `/auth` when user is not authorized*/}
      {!isAuthorized ? (
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}

      <MasterLayout>
        <PrivateRoutes />
      </MasterLayout>
    </Switch>
  )
}

export { Routes }