/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'

export function TermsPage() {
  const intl = useIntl()

  return (
    <>
      <div className="card-body fs-6 text-gray-700">
        <div className="pb-10">
          <h1 className="anchor fw-bolder mb-5" id="overview">
            <a href="#overview"></a>Overview</h1>
          <div className="py-5">Get vector icons and social logos on your website with
            <a href="https://fontawesome.com/" className="fw-bold" target="_blank">Font Awesome</a>, the web's most popular icon set and toolkit. Font Awesome Free package included in Metronic and can be used right away by browsing the
            <a href="https://fontawesome.com/icons?d=gallery&amp;m=free" className="fw-bold" target="_blank">Free Font Awesome Icons</a>.</div>
        </div>
        <div className="py-10">
          <h1 className="anchor fw-bolder mb-3" id="usage">
            <a href="#usage"></a>Usage</h1>
          <div className="py-5">Use Font Awesome icons with spesific class as explained in the
            <a className="fw-bold" href="https://fontawesome.com/icons/affiliatetheme?style=brands" target="_blank">Icon Preview Page</a>:</div>
        </div>
      </div>
    </>
  )
}