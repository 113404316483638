/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

export function RepositoryMenu() {
  const intl = useIntl()
  return (
    <div className='row' data-kt-menu-dismiss='true'>
      <div className='col-lg-4 border-left-lg-1'>
        <div className='menu-inline menu-column menu-active-bg'>
          <div className='menu-item'>
            <Link to='/projects' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'MENU.PROJECTS' })}</span>
            </Link>
          </div>
          <div className='menu-item'>
            <Link to='/tenants' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'MENU.TENANTS' })}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-lg-4 border-left-lg-1'>
        <div className='menu-inline menu-column menu-active-bg'>
          <div className='menu-item'>
            <Link to='/repository/countries' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'MENU.COUNTRIES' })}</span>
            </Link>
          </div>
          <div className='menu-item'>
            <Link to='/repository/regions' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'MENU.REGIONS' })}</span>
            </Link>
          </div>
          <div className='menu-item'>
            <Link to='/repository/delegations' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'MENU.DELEGATIONS' })}</span>
            </Link>
          </div>
          <div className='menu-item'>
            <Link to='/repository/cities' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'MENU.CITIES' })}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-lg-4 border-left-lg-1'>
        <div className='menu-inline menu-column menu-active-bg'>
          <div className='menu-item'>
            <Link to='/repository/genders' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'MENU.GENDERS' })}</span>
            </Link>
          </div>
          <div className='menu-item'>
            <Link to='/repository/currencies' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'MENU.CURRENCIES' })}</span>
            </Link>
          </div>
          <div className='menu-item'>
            <Link to='/repository/icons-svg' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'MENU.ICONS_SVG' })}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}