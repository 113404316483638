import { useEffect } from 'react'
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

export function Logout() {
  useEffect(() => {
    localStorage.removeItem("user");
    const current = window.location.origin + '/auth/confirmtoken/';
    window.location.href = LOGIN_URL ? LOGIN_URL + 'logout/?redirect=' + PROJECT_ID : '';
  }, [])

  return (
    <></>
  )
}