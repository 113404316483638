import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { Logout } from '../pages/auth/login/Logout'

export function PrivateRoutes() {
  const CompanyViewerPage = lazy(() => import('../pages/company/CompanyViewer'))
  const CompaniesPage = lazy(() => import('../pages/company/CompaniesPage'))
  const PersonViewerPage = lazy(() => import('../pages/person/PersonViewer'))
  const PersonsPage = lazy(() => import('../pages/person/PersonsPage'))
  const TenantsPage = lazy(() => import('../pages/tenant/tenants/TenantsPage'))
  const ProjectsPage = lazy(() => import('../pages/project/projects/ProjectsPage'))
  const DelegationsPage = lazy(() => import('../pages/repository/delegations/DelegationsPage'))
  const CitiesPage = lazy(() => import('../pages/repository/cities/CitiesPage'))
  const RegionsPage = lazy(() => import('../pages/repository/regions/RegionsPage'))
  const CountriesPage = lazy(() => import('../pages/repository/countries/CountriesPage'))
  const CurrenciesPage = lazy(() => import('../pages/repository/currencies/CurrenciesPage'))
  const GendersPage = lazy(() => import('../pages/repository/genders/GendersPage'))
  const SvgIconsPageWrapper = lazy(() => import('../pages/repository/icons-svg/SvgIconsPageWrapper'))
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/company/:id' component={CompanyViewerPage} />
        <Route path='/person/:id' component={PersonViewerPage} />
        <Route path='/logout' component={Logout} />
        <Route path='/companies' component={CompaniesPage} />
        <Route path='/persons' component={PersonsPage} />
        <Route path='/tenants' component={TenantsPage} />
        <Route path='/projects' component={ProjectsPage} />
        <Route path='/repository/icons-svg' component={SvgIconsPageWrapper} />
        <Route path='/repository/delegations' component={DelegationsPage} />
        <Route path='/repository/cities' component={CitiesPage} />
        <Route path='/repository/genders' component={GendersPage} />
        <Route path='/repository/regions' component={RegionsPage} />
        <Route path='/repository/countries' component={CountriesPage} />
        <Route path='/repository/currencies' component={CurrenciesPage} />
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}