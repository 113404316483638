/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router-dom';
import { KTSVG } from '../../../../_metronic/helpers'
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

export function LoginChoice() {
  const intl = useIntl();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = LOGIN_URL ? LOGIN_URL + '?redirect=' + PROJECT_ID : '';
    }, 5000)
  }, []);

  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'TEXT.LOGIN.CHOICE.TITLE' })}</h1>

        <div className="alert alert-primary d-flex align-items-center p-5 my-10">
          <KTSVG
            path='/media/icons/duotune/abstract/abs051.svg'
            className='svg-icon svg-icon-2hx svg-icon-primary me-4'
          />
          <span className='spinner-border spinner-border-sm align-middle ms-2' style={{ position: 'absolute', left: 19, color: 'white' }}></span>
          <div className="d-flex flex-column">
            <h4 className="mb-1 text-primary">{intl.formatMessage({ id: 'BUTTON.PLEASE_WAIT' })}</h4>
            <span>{intl.formatMessage({ id: 'TEXT.LOGIN.REDIRECT' })}</span>
          </div>
        </div>
      </div>
    </>
  )
}